<template>
  <div class="launch">
    <Navbar />
    <div class="card">
      <div class="game-new">
        <Button label="New" icon="pi pi-plus" @click="visible = true" />
      </div>
      <p>
        Create a new game to get started. You must have enough purchases available to do this. Once a game is created
        you can start it when you are ready to play. It takes around 5 minutes to start a game. Once a game is started you can open it
        and play. Once you have started the game it cannot be stopped until it is completed. Once a game is completed
        the arena will be destroyed and you will be able to download a certificate.
      </p>
      <DataView
        :value="gameItems"
        :layout="layout"
        :paginator="true"
        :rows="9"
        :sortOrder="sortOrder"
        :sortField="sortField"
      >
        <template #empty>No games found. Create a game to continue.</template>
        <template #header>
          <div class="grid grid-nogutter">
            <div class="col-6 cart-header" style="text-align: left">
              Used {{ singleplayerGamesUsed }}/{{ singleplayerGamesOwned }} single player
              games {{ multiplayerGamesUsed }}/{{ multiplayerGamesOwned }}
              multi player games
            </div>
          </div>
        </template>

        <template #list="slotProps">
          <div class="col-12 md:col-12 sm:col-12">
            <div class="product-list-item">
              <img :src="'products/controller_64.png'" :alt="slotProps.data.game_id" />
              <div class="product-list-detail">
                <div class="product-name">{{ slotProps.data.team_name }}</div>
                <div class="product-description">
                  {{ slotProps.data.game_id }}
                </div>
                <i class="pi pi-cog product-category-icon"></i
                ><span class="product-category">{{ slotProps.data.status }}</span>
              </div>
              <div class="product-list-action">
                <Button
                  label="Start"
                  icon="pi pi-play"
                  @click="startGame(slotProps.data.game_id)"
                  :disabled="createDisabled(slotProps.data.status)"
                />
                <Button
                  label="Open"
                  icon="pi pi-folder-open"
                  @click="openGame(slotProps.data.game_id)"
                  :disabled="openDisabled(slotProps.data.status)"
                />
                <Button
                  label="Cert"
                  icon="pi pi-file-pdf"
                  @click="openCertificate(slotProps.data.game_id)"
                  :disabled="certDisabled(slotProps.data.status)"
                />
              </div>
            </div>
          </div>
        </template>
      </DataView>
      <div class="game-create-dialog">
        <Dialog v-model:visible="visible" modal>
          <GameCreate @close-dialog="closeDialog" class="game-create-dialog" />
        </Dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import GameCreate from "@/components/game/Game_Create";
import Navbar from "@/components/core/Navbar";

export default {
  name: "Launch",
  components: {
    GameCreate,
    Navbar,
  },
  data() {
    return {
      layout: "list",
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: "Price High to Low", value: "!price" },
        { label: "Price Low to High", value: "price" },
      ],
      visible: false,
    };
  },
  computed: {
    ...mapGetters([
      "singleplayerGamesOwned",
      "multiplayerGamesOwned",
      "singleplayerGamesUsed",
      "multiplayerGamesUsed",
      "gameItems",
    ]),
  },
  created() {
    console.log("Launch created");
    this.$store.dispatch("getPurchases");
    this.$store.dispatch("getGameItems");
  },
  mounted() {
    this.$store.dispatch("startUpdateGamesState");
  },
  beforeUnmount() {
    this.$store.dispatch("stopUpdateGamesState");
  },
  methods: {
    ...mapActions(["startGame", "openGame", "openCertificate"]),
    createDisabled(status) {
      console.log(status);
      if (status === "created") {
        return false;
      } else {
        return true;
      }
    },

    openDisabled(status) {
      console.log(status);
      //TODO: update logic here for when we should be able to open a game
      if (
        status === "starting" ||
        status === "started" ||
        status === "provisioning" ||
        status === "running" ||
        status === "destroying" ||
        status === "completed"
      ) {
        return false;
      } else {
        return true;
      }
    },
    certDisabled(status) {
      console.log(status);
      //TODO: update logic here for when we should be able to open a certificate which is basically when it is completed or destroyed
      if (
        // status === "starting" ||
        // status === "started" ||
        // status === "provisioning" ||
        // status === "running" ||
        status === "destroying" ||
        status === "destroyed" ||
        status === "completed"
      ) {
        return false;
      } else {
        return true;
      }
    },
    closeDialog() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  //background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

.game-create-dialog {
  // width: 80vh;
  height: 90vh;
  width: auto;
}

.cert-button {
  width: 16rem;
}

.open-button {
  width: 6rem;
}

.start-button {
  width: 6rem;
}

.game-new {
  margin-bottom: 1rem;
  // border-bottom: 1px solid var(--surface-border);
}

// .p-dropdown {
//   width: 14rem;
//   font-weight: normal;
// }

// .product-name {
//   font-size: 1.5rem;
//   font-weight: 700;
// }

// .product-description {
//   margin: 0 0 1rem 0;
// }

// .product-category-icon {
//   vertical-align: middle;
//   margin-right: 0.5rem;
// }

// .product-category {
//   font-weight: 600;
//   vertical-align: middle;
// }

::v-deep(.product-list-item) {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  // .p-rating {
  //   margin: 0 0 0.5rem 0;
  // }

  // .product-price {
  //   font-size: 1.5rem;
  //   font-weight: 600;
  //   margin-bottom: 0.5rem;
  //   align-self: flex-end;
  // }

  .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: 0.5rem;
  }
}

// ::v-deep(.product-grid-item) {
//   margin: 0.5rem;
//   border: 1px solid var(--surface-border);

//   .product-grid-item-top,
//   .product-grid-item-bottom {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }

//   img {
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//     margin: 2rem 0;
//   }

//   .product-grid-item-content {
//     text-align: center;
//   }

//   // .product-price {
//   //   font-size: 1.5rem;
//   //   font-weight: 600;
//   // }
// }

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    // .product-price {
    //   align-self: center;
    // }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
